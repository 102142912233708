import { BaseModel } from "../base.model";
import { FFTipoviFormiPodaciModel } from "./FFTipoviFormiPodaciModel";
import { UntypedFormGroup } from "@angular/forms";

export class FFTipoviFormiDto extends BaseModel {
autoBr: number;
    sifra: number;
    naziv: string;
    aktivnaForma: boolean | null;
    autoFill: boolean | null;
    zabranaRemoteUnosa: boolean | null;
    ffTipoviFormiPodaci: FFTipoviFormiPodaciModel[];

    get initialValues() {
        return { ffTipoviFormiPodaci: [] };
    }

    get childModels() {
        return {
            items: FFTipoviFormiPodaciModel,
            ffTipoviFormiPodaci: FFTipoviFormiPodaciModel,
        };
    }

    getForm(disable = true): UntypedFormGroup {
        return this.fb.group({
            autoBr: this.autoBr ? this.autoBr : 0,
            sifra: this.sifra ? this.sifra : 0,
            naziv: this.naziv ? this.naziv : '',
            aktivnaForma: this.aktivnaForma ? this.aktivnaForma : false,
            autoFill: this.autoFill ? this.autoFill : false,
            zabranaRemoteUnosa: this.zabranaRemoteUnosa ? this.zabranaRemoteUnosa : false,
        });
    }

    createApiObject(isCreate?: boolean, model?: this) {
        const object = JSON.parse(JSON.stringify(model || this));
        if (isCreate) {
            delete object.autoBr;
        }
        delete object.fb;
        return this.toApi(object);
    }


    get unsafeApiAttributes(): string[] {
        return [...super.unsafeApiAttributes, 'context'];
    }

    get zabranaRemoteUnosaTranslate() {
        if (this.zabranaRemoteUnosa == true) return "DA";
        return "NE"
    }

    get autoFillTranslate() {
        if (this.autoFill == true) return "DA";
        return "NE"
    }

    get aktivnaFormaTranslate() {
        if (this.aktivnaForma == true) return "DA";
        return "NE"
    }
}